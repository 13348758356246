import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';

import {PhotoSwipeGallery} from 'react-photoswipe-2';
import 'react-photoswipe-2/lib/photoswipe.css';

class PortfolioPostTemplate extends React.Component {

  constructor() {
    super();

    this.state = {
      schemes_items: [],
      photos_items: [],
      schemes_options: {
        galleryUID: 1,
        history: false,
        closeOnScroll: false,
        showHideOpacity: true,
      },
      photos_options: {
        galleryUID: 2,
        history: false,
        closeOnScroll: false,
        showHideOpacity: true,
      },
      thumbnails: null,
      msnry: null,
    }
  }

  getThumbnailContent = (item) => {
    return (
      <img src={item.thumbnail} alt={item.alt} />
    );
  }

  imgSize = (instance, index, item) => {
    if (item.w < 1 || item.h < 1) {
      var img = new Image();
      img.onload = function() {
        item.w = this.width;
        item.h = this.height;
        instance.invalidateCurrItems();
        instance.updateSize(true);
      }
      img.src = item.src;
      img.alt = item.alt;
    }
  }

  componentWillMount() {
    var self = this,
        post = this.props.data.markdownRemark,
        schemes = this.props.data.schemeImages.edges,
        thumbnail = this.props.data.thumbnailImages.edges,
        photos = this.props.data.sliderImages.edges;

    const placeholdersLang = this.props.pageContext.intl.messages;

    for (var ii = 0; ii < schemes.length; ii++) {
      self.state.schemes_items.push({
        src: schemes[ii].node.childImageSharp.fluid.src,
        thumbnail: schemes[ii].node.childImageSharp.fluid.src,
        alt: placeholdersLang.postGalleryPlaceholder + ' "' + post.frontmatter.title + '" ' + placeholdersLang.postGalleryPlaceholderScheme + ' ' + (ii + 1),
        w: 0,
        h: 0,
      })
    }

    for (var iii = 0; iii < photos.length; iii++) {
      self.state.photos_items.push({
        src: photos[iii].node.childImageSharp.fluid.src,
        thumbnail: thumbnail[iii].node.childImageSharp.fluid.src,
        alt: placeholdersLang.postGalleryPlaceholder + ' "' + post.frontmatter.title + '" ' + placeholdersLang.postGalleryPlaceholderPhoto + ' ' + (ii + 1),
        w: 0,
        h: 0,
      })
    }

  }

  componentDidMount() {
    var elem = document.querySelector('.project-photos-slider').querySelector('.pswp-thumbnails');
    this.setState({
      thumbnails: elem,
      msnry: new Masonry( elem, {
        itemSelector: '.pswp-thumbnail',
        columnWidth: '.pswp-thumbnail',
        percentPosition: true
      })
    });
  }

  componentDidUpdate() {
    var self = this;
    if(this.state.msnry) {
      imagesLoaded( self.state.thumbnails, function() {
        self.state.msnry.layout();
        self.state.msnry.reloadItems();
      });
    }
  }

  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const intl = this.props.pageContext.intl;
    const { previous, next } = this.props.pageContext

    return (
      <Layout title={siteTitle}>
        <SEO
          lang={intl.language}
          title={post.frontmatter.title + " " + intl.messages.title_prefix}
          description={post.excerpt}
        />

        <div className="project">
          <div className="container">
            <div className="project-image"><Img fluid={post.frontmatter.preview.childImageSharp.fluid} alt={post.frontmatter.title + intl.messages.portfolioPhotoAlt} /></div>
            <h1 className="project-title">{post.frontmatter.title}</h1>
            <div className="project-content">
              <div className="project-meta">
                <h3 className="project-meta-title"><FormattedMessage id="postDetails" /></h3>
                {(post.frontmatter.size)?(
                  <div className="project-meta-size">
                  {post.frontmatter.size}
                  </div>
                ):('')}
                <div className="project-meta-location">{post.frontmatter.location}</div>
              </div>
              <div className="project-text" dangerouslySetInnerHTML={{ __html: post.html }} />
            </div>

            <div className="project-schemes">

              <PhotoSwipeGallery className="project-schemes-slider" items={this.state.schemes_items} options={this.state.schemes_options} thumbnailContent={this.getThumbnailContent} gettingData={this.imgSize}/>

            </div>

            <div className="project-photos">

              <PhotoSwipeGallery className="project-photos-slider" items={this.state.photos_items} options={this.state.photos_options} thumbnailContent={this.getThumbnailContent} gettingData={this.imgSize}/>

            </div>

            <div className="project-nav">
              <ul>
                <li>
                  {previous && (
                    <Link className="link" to={previous.fields.slug} rel="prev">
                      <i className="arrow--left arrow--left-light"></i> {previous.frontmatter.title}
                    </Link>
                  )}
                </li>
                <li>
                  {next && (
                    <Link className="link" to={next.fields.slug} rel="next">
                      {next.frontmatter.title} <i className="arrow--right arrow--right-light"></i>
                    </Link>
                  )}
                </li>
              </ul>
            </div>

          </div>
        </div>
      </Layout>
    )
  }
}

export default injectIntl(PortfolioPostTemplate)

export const pageQuery = graphql`
  query PortfolioPostBySlug($slug: String!, $gallery: String!, $thumbnail: String!, $scheme: String!, $language: String) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug }, language: { eq: $language } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        number
        size
        location
        preview {
          childImageSharp {
            fluid(quality: 100, maxWidth: 5000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    sliderImages: allFile(
      sort: { fields: name, order: ASC }
      filter: { extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: { eq: $gallery } }
    ) {
      edges {
        node {
          id
          name
          childImageSharp {
            fluid(quality:100, maxWidth: 5000) {
              src
            }
          }
        }
      }
    }
    thumbnailImages: allFile(
      sort: { fields: name, order: ASC }
      filter: { extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: { eq: $thumbnail } }
    ) {
      edges {
        node {
          id
          name
          childImageSharp {
            fluid(quality:90, maxWidth: 2000) {
              src
            }
          }
        }
      }
    }
    schemeImages: allFile(
      sort: { fields: name, order: ASC }
      filter: { extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: { eq: $scheme } }
    ) {
      edges {
        node {
          id
          name
          childImageSharp {
            fluid(quality:100, maxWidth: 2000) {
              src
            }
          }
        }
      }
    }
  }
`